<template>
  <div class="flex items-center">
    <div>
      <erp-select :options="options" v-model="criterio.comparativo" />
    </div>
    <div class="m-l">
      <comitente-select class="u-field-no-label" @loaded="$emit('loadedComitente')" :columns="[
                      {label: 'Nome', value: 'label'},
                      {label: 'Tipo', value: 'tipoName'}
                    ]" v-model="criterio.value" />
    </div>
  </div>
</template>

<script>
import {equals} from './matches/equals'
import {ErpSelect} from 'uloc-vue-plugin-erp'
import ComitenteSelect from '../../../comitente/helpers/input/ComitenteSelect'
import criteriaMixin from './criteriaMixin'

export default {
  name: 'Comitente',
  mixins: [criteriaMixin],
  props: {
    criterio: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      options: [
        ...equals
      ]
    }
  },
  methods: {
    loadedComitente (data) {
      console.log('Loaded', data)
    }
  },
  components: {ComitenteSelect, ErpSelect}
}
</script>
